import React from 'react';
import Helmet from 'react-helmet';
import smoothJumpMenus from './src/util/smoothJumpMenus';
import WithLocation from './src/components/WithLocation';

import NProgress from 'nprogress';

import { ApolloProvider } from 'react-apollo';
import { client } from './src/util/apollo/client';
import 'nprogress/nprogress.css';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
NProgress.configure({ showSpinner: false });

export const onInitialClientRender = () => {
  // NProgress.done();
  smoothJumpMenus();
};

const initiateNProgress = (timeout = 0) => {
  const nProgressTimeout = setTimeout(() => {
    NProgress.start();
  }, timeout);
  $(document).ready(function () {
    // NProgress.done();
    clearTimeout(nProgressTimeout);
  });
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // const params = new URLSearchParams(location?.location?.search);
  // const view = params.get('view');
  // if (view === 'full') {
  //   setTimeout(() => {
  //     smoothJumpMenus();
  //   }, 1000);
  // } else {
  //   smoothJumpMenus();
  // }
  console.log('onPreRouteUpdate');
  initiateNProgress(500);
};

export const onRouteUpdateDelayed = props => {
  console.log('onRouteUpdateDelayed');
  // called if route change isn't initiated after 1000 ms
  initiateNProgress(0);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('onRouteUpdate');
  NProgress.done();
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null
  }



  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }

  // const { delayOnRouteUpdate = 0 } = pluginConfig

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, 0))
    })
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + 0)
  }





  return null
};

export const wrapRootElement = WithLocation(({ element, location }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          'data-page-path': location.location.pathname
            .substr(1)
            .replace(/\/+$/, '')
            .replace(/\//g, '-'),
        }}
      />
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </>
  );
});

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if (typeof window.IntersectionObserver === `undefined`) {
    import('intersection-observer');
    console.log(`# IntersectionObserver is polyfilled!`);
  }

  if (typeof window.URLSearchParams === `undefined`) {
    window.URLSearchParams = function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
          self.searchString
        );
        if (results == null) {
          return null;
        } else {
          return decodeURI(results[1]) || 0;
        }
      };
    };
  }
};
