import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { renderToString } from 'react-dom/server';
import Modal from '../../components/Modal';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';
import Layout from '../../layouts/DefaultLayout';
import Loading from '../../components/Loading';
import RandomImageGroup from '../../components/RandomImageGroup';
import Loadable from '@loadable/component';
const CheckAvailabilityFormHome = Loadable(() =>
  import('../page-rentals/components/CheckAvailabilityFormHome')
); // code-splitted, on demand loaded component
// import styles from '../../styles/home.module.scss';
import PropertySearchForm from '../../components/PropertySearchForm';
import SearchByDateForm from '../../components/SearchByDateForm';
import MobileSearchForm from './components/MobileSearchForm';
import HeroBackground from '../../components/HeroBackground';
import PropertyDetailsList from '../../components/PropertyDetailsList';
import LazyImage from '../../components/LazyImage';
import BeachHouseCompoundsText from '../../components/BeachHouseCompoundsText';
import { Modal as LastMinuteDealsModal } from '../../components/LastMinuteDeals';

import { getSiteMeta } from '../../util/providers';
// import VideoCover from 'react-video-cover';
import styles from './styles.module.scss';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
// import $ from 'jquery';
import CheaperHotels from '../../images/cheaper-hotels.svg';
import FiveStarAmenities from '../../images/five-star-amenities.svg';
import Over15Years from '../../images/over-15years.svg';
import PetFriendly from '../../images/pet-friendly.svg';
import GenerousDiscounts from '../../images/generous-discounts.svg';
import LargeGroupsWelcome from '../../images/largegroups-welcome.svg';
import OceanToRiver from '../../images/ocean-river.svg';
import '../../styles/slick.scss';

const SITE_ALERT = gql`
  query SiteAlert {
    siteAlert {
      title
      message
      subTitle
      moreMessage
    }
  }
`;

// const VideoCover =
//   typeof window !== 'undefined' &&
//   $(window).width() > 992 &&
//   Loadable(() => import('react-video-cover'));

// let preloadVideo = true;
var connection =
  typeof window !== 'undefined'
    ? navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    {}
    : {};

const VideoCover = ['slow-2g', '2g', '3g'].includes(connection.effectiveType)
  ? null
  : Loadable(() => import('react-video-cover'));
// if (connection) {
//   if (connection.effectiveType === 'slow-2g') {
//     preloadVideo = false;
//   }
// }

const NextArrow = props => {
  const { className, style, onClick, ...rest } = props;

  return (
    <button
      className={'slick-next'}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      {...rest}
    >
      <img src="/next.svg" width="23" height="40" alt="Next" />
    </button>
  );
};

const PrevArrow = props => {
  const { className, style, onClick, ...rest } = props;
  return (
    <button
      className={'slick-prev'}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      {...rest}
    >
      <img src="/previous.svg" alt="Previous" width="23" height="40" />
    </button>
  );
};

var reactSlickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: renderToString(<NextArrow aria-label="Next Promotions" />),
  prevArrow: renderToString(<PrevArrow aria-label="Previous Promotions" />),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const ConditionalForms = ({ location, filteredProperties }) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const [availabilityModalIsOpen, setAvailabilityModalIsOpen] = useState(
    false
  );
  const params = new URLSearchParams(location?.search);

  //const [dateSearchState, setDateSearchState] = useState(false)
  React.useEffect(() => {
    setHasMounted(true);


  }, []);
  if (!hasMounted) {
    return null;
  }
  const datesearch = params.get('onlinebooking') == 'true' ? 'true' : Cookies.get('onlinebooking');
  return <>
    {datesearch ? <div className="_d-none _d-lg-block">

      <button
        key="rental-search-button"
        id="rental-search-button"
        onClick={() => {
          setAvailabilityModalIsOpen(true);
        }}
        className={`
          btn btn-secondary text-primary font-weight-bold rounded`}
      >
        <img
          className="mr-1plus"
          style={{
            verticalAlign: 'sub'
          }}
          width="20"
          height="20"
          src="/icon-calendar.svg"
        />Search Dates
      </button>
      <div className="mt-1plus">
        <Link className="text-white " href="/rentals"><u>Advanced Search</u></Link>
      </div>

      <Modal
        isOpen={availabilityModalIsOpen}
        disableFocusTrap={true}
        title={'Availability'}
        onClose={() => setAvailabilityModalIsOpen(false)}
      >
        <CheckAvailabilityFormHome
          loading={false}
          results={23}
          onClose={() => setAvailabilityModalIsOpen(false)}
        />
      </Modal>

    </div> :
      <div><PropertySearchForm
        modalIsOpen={false}
        id="home-page-select-list"
      /><MobileSearchForm id="mobile-form-2" /></div>}</>
}

const IndexPage = ({ data, pageContext, location, ...props }) => {
  let videoRef = useRef();
  //console.log('location', location);
  const params = new URLSearchParams(location?.search);
  const onlinebooking = params.get('onlinebooking') == 'true' ? 'true' : Cookies.get('onlinebooking');
  const datesearch = params.get('datesearch') == 'true' ? 'true' : Cookies.get('datesearch');

  //console.log('params', params.get('sitealert'));
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [dateSearchState, setDateSearchState] = useState(datesearch)
  const { sitePage } = data.bhip;
  const videoOptions = {
    src:
      'https://images.beachhousesinparadise.com/videos/bhip-homepage-video-loop-02.mp4',
    ref: ref => {
      videoRef = ref;
    },
    autoPlay: true,
    muted: true,
    loop: true,
  };
  const siteMeta = getSiteMeta();
  useEffect(() => {
    $('#promotional-slider').slick(reactSlickSettings);
  }, []);
  const filteredCombos = data.bhip.properties.filter(
    property =>
      property.type === 'COMBO' && property?.sitePage?.status === 'PUBLISHED'
  );

  const filteredProperties = data.bhip.properties.filter(
    property =>
      property?.sitePage?.status === 'PUBLISHED'
  );
  return (
    <Layout subheader={false}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      >
        <link rel="canonical" href="https://www.beachhousesinparadise" />
      </SEO>
      <>
        <HeroBackground className="hero-header mb-3">
          <div className={`d-none d-lg-block h-100`}>
            {VideoCover && (
              <>
                <VideoCover
                  className={styles.videoCoverWrapper}
                  id="hero-video"
                  videoOptions={videoOptions}
                />
              </>
            )}
          </div>

          <div
            className={`${styles.heroInnerContent
              } m-auto w-50 text-center pt-4 pb-5 pt-lg-5 pb-lg-10`}
          >
            <div
              className={`${styles.heroFont
                } font-weight-normal text-center text-white d-none d-md-block h1 mb-2`}
            >
              Ocean-to-River Beach House Rentals
            </div>
            <>
              <div><ConditionalForms filteredProperties={filteredProperties} location={location} /></div>

            </>

          </div>
        </HeroBackground>
      </>
      <div className="container mt-2">
        <div className="row">
          <div id="beachhouses" className="col text-center">
            <h1 className="mb-2 font-weight-normal">
              Florida Beach House Rentals
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="m-auto col-sm-10 px-sm-0 px-md-0 px-lg-2 col-lg-12">
            <p>
              {siteMeta.siteName} operates dozens of 5-star-rated luxury beachfront rentals in award-winning Stuart, Florida (USA Today 2024 National Winner, "America's Best Coastal Town"). Amenities include heated swimming pools, private elevators, and beachfront jacuzzi hot tubs. All-inclusive rates cover kayaks, bicycles, and other toys without surcharges. Situated on a narrow ocean-to-river barrier island, most beach homes feature private docks as well.
              {!showPropertyDetails && <span
                data-toggle="collapse"
                data-target="#collapseRentalDetails"
                aria-expanded="false"
                aria-controls="collapseRentalDetails"

              >
                ...
                <button
                  className={`btn btn-link py-0 pl-0 align-baseline`}
                  type="button"
                  onClick={() => setShowPropertyDetails(true)}
                >
                  more
                </button>
              </span>}{' '}
              {showPropertyDetails && <span id="collapseRentalDetails">
                Our beach house rentals are located along the Treasure Coast, in the Jensen Beach and beautiful South Hutchinson Island area, conveniently close to both Miami/Ft. Lauderdale and Orlando/Disney. Experience five-star service and four-diamond luxury at Florida beach rentals for less than $100 a night. We've pioneered this concept for over 15 years, now known as "HomeTels". Our 5-star-rated beachfront HomeTels offer luxury without the price tag — most beach homes start at less than $100 per night per private bedroom. Unlike hotels, we rent entire houses on quiet, relaxing beaches. Gather everyone under one roof, just like home, but with a stunning beachfront setting.
              </span>}
            </p>
          </div>
        </div>
        <>
          <div className="row p-0 px-lg-2">
            {data.bhip &&
              data.bhip.properties
                .filter(
                  property =>
                    property.type !== 'COMBO' &&
                    property.sitePage &&
                    (property.sitePage || {}).featuredImageCollection &&
                    property?.sitePage?.status === 'PUBLISHED'
                )
                .map((filteredProperty, index) => {
                  // const mediumFeaturedImage =
                  //   filteredProperty?.sitePage?.featuredImage?.file?.medium
                  //     ?.fullUrl || '';
                  // const tinyFeaturedImage =
                  //   filteredProperty?.sitePage?.featuredImage?.file?.tiny
                  //     ?.fullUrl || '';
                  return (
                    <div
                      className="mx-auto mx-lg-0 mb-3 col-sm-10 col-lg-6 col-xl-4 p-0 p-lg-0plus"
                      key={filteredProperty.id}
                    >
                      <div /*className="no-gutters-sm-down"*/>
                        <Link
                          to={`/${filteredProperty.sitePage.slug}`}
                          className="aspect ratio-4x3 mb-2"
                        >
                          <RandomImageGroup
                            files={
                              filteredProperty.sitePage.featuredImageCollection
                                .files
                            }
                            alt={`${filteredProperty.name
                              } vacation rental property`}
                          />
                          {filteredProperty.status === 'COMING_SOON' && (
                            <span className="thumbnail-label ">
                              Coming Soon
                            </span>
                          )}
                        </Link>
                      </div>
                      <div className="px-2 px-sm-0">
                        <Link
                          to={`/${filteredProperty.sitePage.slug}`}
                          className="h2 font-weight-bold"
                        >
                          {filteredProperty.name}
                          {filteredProperty.status === 'NEW' && (
                            <span className="ml-1">
                              (
                              <span className={`${styles.newPropertyText}`}>
                                New
                              </span>
                              )
                            </span>
                          )}
                        </Link>

                        <div className="mt-1plus">
                          <PropertyDetailsList property={filteredProperty} />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
          {filteredCombos.length > 0 && (
            <div className="row p-0 px-lg-2">
              <div className="col col-sm-10 mx-auto mx-lg-0 px-2 px-sm-0 p-lg-0plus">
                <BeachHouseCompoundsText />
              </div>
            </div>
          )}
          <div className="row p-0 px-lg-2">
            {filteredCombos.length > 0 &&
              filteredCombos.map((filteredProperty, index) => {
                // const mediumFeaturedImage =
                //   filteredProperty?.sitePage?.featuredImage?.file?.medium
                //     ?.fullUrl || '';
                // const tinyFeaturedImage =
                //   filteredProperty?.sitePage?.featuredImage?.file?.tiny
                //     ?.fullUrl || '';

                return (
                  <div
                    className="mx-auto mx-lg-0 mb-3 col-sm-10 col-lg-6 col-xl-4 p-0 p-lg-0plus"
                    key={filteredProperty.id}
                  >
                    <div /*className="no-gutters-sm-down"*/>
                      <Link
                        to={`/${filteredProperty.sitePage.slug}`}
                        className="aspect ratio-4x3 mb-2"
                      >
                        <RandomImageGroup
                          files={
                            filteredProperty.sitePage.featuredImageCollection
                              .files
                          }
                          alt={`${filteredProperty.name
                            } vacation rental property`}
                        />
                      </Link>
                    </div>
                    <div className="px-2 px-sm-0">
                      <Link
                        to={`/${filteredProperty.sitePage.slug}`}
                        className="h2 font-weight-bold"
                      >
                        {filteredProperty.name}
                        {filteredProperty.status === 'NEW' && (
                          <span className="ml-1">
                            (
                            <span className={`${styles.newPropertyText}`}>
                              New
                            </span>
                            )
                          </span>
                        )}
                      </Link>

                      <div className="mt-1plus">
                        <PropertyDetailsList property={filteredProperty} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row p-0 px-lg-2">
            <div className="mx-auto mt-4 col-sm-10 col-lg-12">
              <h2 className="text-center mb-3">
                Private Beach House Rentals in Stuart, Jensen Beach, & Ft.
                Pierce FL
              </h2>
              <div
                id="promotional-slider"
                className={`slick-eq-height slick-separators slick-fade-in`}
              >
                <div>
                  <div className={`bg-white`}>
                    <Link
                      to="/birds-eye-view"
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      title="See aerial photos of Hutchinson Island Beach House rentals"
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.oceanRiver}  mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={OceanToRiver}
                      />

                      <span className="font-weight-bold">Ocean-to-River</span>
                    </Link>
                    <div className="text-center">
                      Most beach houses sit <br /> directly on both the ocean{' '}
                      <br /> and river
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`bg-white`}>
                    <Link
                      to="/is-a-florida-beach-house-cheaper-than-a-hotel"
                      title="Read more about pricing at our luxury beach homes"
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.cheaperHotels} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={CheaperHotels}
                      />

                      <span className="font-weight-bold">
                        Cheaper than hotels
                      </span>
                    </Link>
                    <div className="text-center">
                      Entire homes starting at less <br /> than $100/night per
                      <br /> bedroom
                    </div>
                  </div>
                </div>

                <div>
                  <div className={`bg-white`}>
                    <Link
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      to="/five-star-amenities"
                      title="Read more about amenities room-by-room"
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.fiveStarAmenities} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={FiveStarAmenities}
                      />
                      <span className="font-weight-bold">5-star amenities</span>
                    </Link>
                    <div className="text-center">
                      Five-star service & <br /> four-diamond luxury <br />{' '}
                      vacation living
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`bg-white`}>
                    <Link
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      to="/about-us"
                      title={`Read more about ${siteMeta.siteName}`}
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.over15Years} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={Over15Years}
                      />
                      <span className="font-weight-bold">20 year leader</span>
                    </Link>

                    <div className="text-center">
                      Delivering amazing vacation <br /> experiences to
                      discerning & <br />
                      knowledgeable travelers
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`bg-white`}>
                    <Link
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      to="/this-beach-house-welcomes-pets"
                      title="Read more about our pet friendly beach house rentals"
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.petFriendly} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={PetFriendly}
                      />

                      <span className="font-weight-bold">Pet friendly</span>
                    </Link>
                    <div className="text-center">
                      You'll find many pet-friendly <br /> amenities and dog
                      friendly <br />
                      beaches
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`bg-white`}>
                    <Link
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      to="/discount-programs"
                      title="Read more about Florida vacation discounts"
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.generousDiscounts} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={GenerousDiscounts}
                      />
                      <span className="font-weight-bold">
                        Generous discounts
                      </span>
                    </Link>
                    <div className="text-center">
                      <span className="text-nowrap">
                        Over ten ways to save when
                      </span>{' '}
                      <br /> you book your next beach <br /> getaway
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`bg-white`}>
                    <Link
                      className={`text-center w-100 d-flex flex-column align-items-center`}
                      to="/large-groups"
                      title="Read more about maximum occupancy"
                    >
                      {/* <i
                        className={`${styles.promoIcon} ${styles.largeGroupsWelcome} mb-2`}
                      /> */}
                      <img
                        // className={`${styles.promoIcon}`}
                        className="mb-2"
                        src={LargeGroupsWelcome}
                      />
                      <span className="font-weight-bold">
                        Large groups welcome
                      </span>
                    </Link>
                    <div className="text-center">
                      <span className="text-nowrap">
                        Discounts for groups larger
                      </span>{' '}
                      <br /> than any one home can <br />
                      handle
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Link to="/property/">Go to page 2</Link> */}
        </>
      </div>
      {0 == 1 && params.get('sitealert') !== 'false' && <LastMinuteDealsModal />}
    </Layout>
  );
};

export const query = graphql`
  query SiteHomePage($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        status
        id
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        type
        sitePage {
          slug
          status
          featuredImageCollection {
            files {
              tiny: version(where: { key: "tiny" }) {
                fullUrl
              }
              medium: version(where: { key: "medium" }) {
                fullUrl
              }
            }
          }
          # featuredImage {
          #   alt
          #   file {
          #     medium: version(where: { key: "medium" }) {
          #       fullUrl
          #     }
          #     tiny: version(where: { key: "tiny" }) {
          #       fullUrl
          #     }
          #   }
          # }
        }
      }
    }
  }
`;
export default IndexPage;
