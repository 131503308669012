import React, { useState, useEffect, createContext, useRef } from 'react';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
// import ResponsiveModal from '../../components/ResponsiveModal';
import ChipsList from './components/ChipsList';
import RandomImageGroup from '../../components/RandomImageGroup';
import { XCircle, CheckCircle } from 'react-feather';
import Cookies from 'js-cookie';
// import CheckAvailabilityForm from './components/CheckAvailabilityForm';
import Loadable from '@loadable/component';
const CheckAvailabilityForm = Loadable(() =>
  import('./components/CheckAvailabilityForm')
); // code-splitted, on demand loaded component
import PropertySearchForm from '../../components/PropertySearchForm';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import moment from 'moment'
import { graphql } from 'gatsby';
import Layout from '../../layouts/PropertySearchLayout';
import { navigate } from '@reach/router';
import styles from './styles.module.scss';
import PropertyDetailsList from '../../components/PropertyDetailsList';
import LazyImage from '../../components/LazyImage';
// import VacationRentalMap from './components/VacationRentalMap';

const VacationRentalMap = Loadable(() =>
  import('./components/VacationRentalMap')
); // code-splitted, on demand loaded component
import PropertySelectProvider, {
  PropertySelectConsumer,
} from '../../contexts/PropertySelect';

import { withApollo, Query } from 'react-apollo';
import gql from 'graphql-tag';
// import HomeAway from '../../images/logo-homeaway.png';
// import TripAdvisor from '../../images/logo-tripadvisor.png';
// import AirBnb from '../../images/logo-airbnb.png';
// import Booking from '../../images/logo-booking.png';
// import VRBO from '../../images/logo-vrbo.png';
import { objectIsEmpty } from '../../util/helpers';

const MoreInfoPopover = () => {
  useEffect(() => {
    var showPopover = function () {
      $(this).popover('toggle');
    },
      hidePopover = function () {
        $(this).popover('hide');
      };
    $('#more-info')
      .popover({
        trigger: 'manual',
      })
      .click(showPopover)
      .blur(hidePopover);

    return () => {
      $('#more-info').popover('dispose');
    };
  }, []);

  return (
    <a
      style={{ cursor: 'pointer' }}
      tabIndex="0"
      id="more-info"
      className="ml-1plus popover-dismiss"
      data-container="body"
      data-toggle="popover"
      data-placement="top"
      data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
      data-content="Rates vary by night, depending on season, select holidays, and other considerations. Please visit your favorite booking site for more info."
    >
      <img alt="More Info" src="/more-info.svg" width="21.5" height="21.5" />
    </a>
  );
};

const platformImageMap = {
  // airbnb: AirBnb,
  // homeaway: HomeAway,
  // tripadvisor: TripAdvisor,
  // bookingcom: Booking,
  // vrbo: VRBO,
};

import {
  updateUrlWithCommaDelimitedString,
  updateUrl,
} from '../../util/urlHelper';
import { formDataToArray } from '../../util/formDataTransformer';

const AVAILABLE_PROPERTIES_QUERY = gql`
  query AvailableProperties(
    $checkIn: Date!
    $checkOut: Date
    $flexibilityInDays: Int
  ) {
    properties(
      where: {
        type: STANDARD
        calendar: {
          availability: {
            checkIn: $checkIn
            checkOut: $checkOut
            flexibilityInDays: $flexibilityInDays
          }
        }
      }
    ) {
      id
      key
    }
  }
`;

const PropertySelectContext = createContext();

const handleSubmit = (e, setAmenitiesModalIsOpen) => {
  e.preventDefault();
  const formDataArray = formDataToArray(e.target);
  const transformedArray = formDataArray.reduce((acc, data) => {
    if (data === 'swimSpa') {
      acc.push('plungePool');
    } else {
      acc.push(data);
    }
    return acc;
  }, []);

  updateUrlWithCommaDelimitedString(
    'rentals',
    transformedArray.join(','),
    'amenities'
  );
  setAmenitiesModalIsOpen(false);
};

const sendToReservePage = (slug) => {
  const params = new URLSearchParams(window.location.search);
  const checkIn = params.get('checkIn');
  const checkOut = params.get('checkOut');
  window.location.href = `/${slug}/?checkIn=${checkIn}&checkOut=${checkOut}`
}

const getPlatformAvailabilityURLSuffix = key => {
  const params = new URLSearchParams(window.location.search);
  const checkIn = params.get('checkIn');
  const checkOut = params.get('checkOut');
  let suffix = '';
  if (checkIn && checkOut) {
    switch (key) {
      case 'vrbo':
        suffix = `?arrival=${checkIn}&departure=${checkOut}`;
        break;
      case 'homeaway':
        suffix = `?arrival=${checkIn}&departure=${checkOut}`;
        break;
      case 'airbnb':
        suffix = `?check_in=${checkIn}&check_out=${checkOut}`;
        break;
      case 'bookingcom':
        suffix = `?checkin=${checkIn}&checkout=${checkOut}`;
        break;
    }
  }
  return suffix;
};

const buildPlatformUrl = (propertyUrl, platformKey) => {
  if (platformKey === 'vrbo') {
    const urlArray = propertyUrl.split('/');
    return `https://www.vrbo.com/${urlArray[urlArray.length - 1]
      }${getPlatformAvailabilityURLSuffix(platformKey)}`;
  }
  return `${propertyUrl}${getPlatformAvailabilityURLSuffix(platformKey)}`;
};

const AmenitiesForm = ({
  propertyAmenities,
  bedrooms,
  bathrooms,
  sleeps,
  amenities,
  type,
  location,
  setAmenitiesModalIsOpen,
}) => {
  const urlParams = new URLSearchParams(location.search);
  const filtersPresent =
    bedrooms > 0 || bathrooms > 0 || sleeps > 0 || amenities.length > 0;



  // console.log('location', location)


  const reorderedAmenityList =
    propertyAmenities &&
    propertyAmenities.reduce((acc, amenity, index) => {
      if (amenity.key !== 'riverfrontRecreationalArea') {
        acc.push(amenity);
      }
      if (index === 0) {
        acc.push(
          propertyAmenities.find(
            amenity => amenity.key === 'riverfrontRecreationalArea'
          )
        );
      }
      return acc;
    }, []);

  return (
    <form
      id="amenities-filter-form"
      onSubmit={e => handleSubmit(e, setAmenitiesModalIsOpen)}
    >
      <div
        className={`container ${type === 'modal' ? '' : 'p-3'}`}
        style={{ width: `${type === 'dropdown' ? '500px' : 'auto'}` }}
      >
        <div className="row">
          {type === 'dropdown' && (
            <div className="col-12 w-100">
              <p className="font-weight-bold mb-2">Filter by Amenities</p>
            </div>
          )}
          {reorderedAmenityList &&
            reorderedAmenityList.map((amenity, index) => {
              const found = amenities.find(item => {
                const amenityItem = item === 'plungePool' ? 'swimSpa' : item;
                return amenityItem === amenity.key;
              });

              return (
                <div
                  key={amenity.key}
                  className={`${(index + 1) % 2 === 0
                    ? 'col-lg-8 px-lg-2'
                    : 'col-lg-4 pr-lg-0 pl-lg-2'
                    } col-12 px-0 form-group ${index + 1 === reorderedAmenityList.length ? 'mb-0' : ''
                    }`}
                >
                  <div className="form-check custom-control custom-checkbox">
                    <input
                      defaultChecked={found}
                      className="form-check-input custom-control-input"
                      type="checkbox"
                      value={amenity.key}
                      id={`check${index + 1}-${type}`}
                      name={`${amenity.key === 'swimSpa' ? 'plungePool' : amenity.key
                        }`}
                    />
                    <label
                      className={`form-check-label custom-control-label ${styles.checkboxLabel}`}
                      htmlFor={`check${index + 1}-${type}`}
                    >
                      {amenity.key === 'swimSpa' ? 'Plunge Pool' : amenity.name}
                    </label>
                  </div>
                </div>
              );
            })}
          <div className="col-12 px-0 px-lg-2 mt-2 form-group">
            <button
              type="submit"
              className={`btn w-100 btn-secondary text-primary font-weight-bold rounded`}
              id="amenity-filter-button"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const getPropertyDisplayHeader = (bedrooms, bathrooms, sleeps) => {
  const filtersArray = getPropertyFiltersArray([
    { string: `Bedrooms ${bedrooms} +`, number: Number(bedrooms) },
    { string: `Baths ${bathrooms} +`, number: Number(bathrooms) },
    { string: `Sleeps ${sleeps} +`, number: Number(sleeps) },
  ]);
  if (filtersArray.length > 0) {
    return filtersArray.reduce((acc, filter, index) => {
      if (index + 1 < filtersArray.length) {
        acc += `${filter}, `;
      } else {
        acc += filter;
      }
      return acc;
    }, '');
  }
  return '';
};

const getPropertyFiltersArray = filterArray => {
  return filterArray.reduce((acc, filter) => {
    if (filter.number > 0) {
      acc.push(filter.string);
    }
    return acc;
  }, []);
};

// const setHorizontalScrollObserver = () => {
//   var callback = function(entries, observer) {
//     console.log('item in view!!');
//     entries.forEach(entry => {
//       // Each entry describes an intersection change for one observed
//       // target element:
//       //   entry.boundingClientRect
//       //   entry.intersectionRatio
//       //   entry.intersectionRect
//       //   entry.isIntersecting
//       //   entry.rootBounds
//       //   entry.target
//       //   entry.time
//     });
//   };
//   var options = {
//     root: document.querySelector('#subheader-navigation'),
//     rootMargin: '0px',
//     threshold: 1.0,
//   };

//   var observer = new IntersectionObserver(callback, options);
//   var target = document.querySelectorAll('[data-chip]');
//   console.log('target', target.length);
//   // observer.observe(target);
// };

const SearchPage = ({ pageContext, data, location, client, ...props }) => {
  console.log('props', props)
  console.log('location.state', location)
  console.log('data', data)
  const { sitePage } = data.bhip;
  const possibleAmenities = data?.bhip?.propertyAmenities || [];
  const params = new URLSearchParams(location.search);


  if (params.get('promo') == 'holiday33' && params.get('utm_source') == 'mailchimp') {
    window.location.href = "/"
    //navigate('/');
    return <></>;
  }
  // console.log('location', location)
  const onlinebooking = params.get('onlinebooking') == 'true' ? 'true' : Cookies.get('onlinebooking');
  const promo = params.get('promo') ? params.get('promo') : Cookies.get('promo');

  const [allowBooking, setAllowBooking] = useState(
    (params.get('preview') == 'true' || onlinebooking == 'true') ? true : false
  );

  const [paypalMessagingModalIsOpen, setPaypalMessagingModalIsOpen] = useState(
    location.state?.paypalSuccess?.checkIn ? true : false
  );
  const propertyBuildData = data?.bhip?.properties
    ? data.bhip.properties.filter(
      property => property.sitePage?.status === 'PUBLISHED'
    )
    : [];

  console.log(
    'propertyBuildData',
    propertyBuildData.filter(property => property.name == 'Aquarius South')
  );

  const urlParams = new URLSearchParams(location.search);
  const availabilityUrlParamPresent = urlParams.get('checkavailability')
    ? true
    : false;
  // const [bedroomsState, setBedrooms] = useState(null);
  // const [bathsState, setBaths] = useState(null);
  // const [sleepsState, setSleeps] = useState(null);
  const mode = urlParams.get('mode');
  const [viewMode, setViewMode] = useState(null);
  const [amenitiesModalIsOpen, setAmenitiesModalIsOpen] = useState(false);
  const [availabilityModalIsOpen, setAvailabilityModalIsOpen] = useState(
    availabilityUrlParamPresent
  );
  const [reserveDatesPropertyId, setReserveDatesPropertyId] = useState(false);
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [moreOptionsModalIsOpen, setMoreOptionsModalIsOpen] = useState(false);
  // const [mapIsLoading, setMapIsLoading] = useState(true);
  const filtersApplied = urlParams.get('checkIn');
  const searchBarRowRef = useRef(null);
  const alertWarning = useRef(null);
  const [hasQueryResults, setHasQueryResults] = useState(false);
  useEffect(() => {
    setViewMode(urlParams.get('mode') || 'grid');

    if (urlParams.get('mode') === 'map') {
      //const height = $('#covid-alert').height();

      setTimeout(() => {
        $('html, body').animate(
          {
            scrollTop: $(searchBarRowRef.current).offset().top,
          },
          0
        );
      }, 500);
    }
    if (!urlParams.get('amenities')) {
      $('.form-check-input').each((index, value) => {
        $(value).prop('checked', false);
      });
    } else {
      $('.form-check-input').each((index, value) => {
        const urlParamsWithSwimSpa = urlParams
          .get('amenities')
          .split(',')
          .reduce((acc, amenity) => {
            if (amenity === 'plungePool') {
              acc.push('swimSpa');
            } else {
              acc.push(amenity);
            }
            return acc;
          }, []);
        if (urlParamsWithSwimSpa.find(amenity => amenity === $(value).val())) {
          $(value).prop('checked', true);
        }
      });
    }

    $('.dropdown-menu.keep-open').on('click.bhip', function (e) {
      if ($(e.target).attr('id') !== 'amenity-filter-button') {
        e.stopPropagation();
      }
    });

    $('#amenity-filter-button').on('click.bhip', function (e) {
      $('#amenities-dropdown-menu')
        .removeClass('show')
        .attr('aria-expanded', false);
    });
    // const mapObserver = new IntersectionObserver(
    //   (entries, imgObserver) => {
    //     console.log('entries[0]', entries[0].isIntersecting);
    //     if (entries[0].isIntersecting) {
    //       setTimeout(() => {
    //         setMapIsLoading(false);
    //       }, 3000);

    //       //   const lazyImage = entry.target;
    //       //   if (lazyImage.dataset.iframeSrc) {
    //       //     loadiFrame(lazyImage);
    //       //   } else {
    //       //     loadFullImage(lazyImage);
    //       //   }
    //       //   imgObserver.unobserve(lazyImage);
    //     }
    //   },
    //   { rootMargin: '200px 0px' }
    // );
    // setTimeout(() => {
    //   const map = document.querySelector('#map-view-page-conteiner');
    //   console.log('map!!', map);
    //   map && mapObserver.observe(map);
    // }, 1000);

    return () => {
      $('#amenity-filter-button').off('click.bhip');
      $('.dropdown-menu.keep-open').off('click.bhip');
    };
  }, [urlParams.get('mode'), hasQueryResults]);

  // const setPropertyTypeValue = (name, value) => {
  //   switch (name) {
  //     case 'bedrooms':
  //       setBedrooms(value);
  //       break;
  //     case 'baths':
  //       setBaths(value);
  //       break;
  //     case 'sleeps':
  //       setSleeps(value);
  //       break;
  //   }
  // };

  const hasAllAmenities = (property, amenities = []) => {
    const tranformedAmenitiesWithSwimSpa = amenities.reduce((acc, amenity) => {
      if (amenity === 'plungePool') {
        acc.push('swimSpa');
      } else {
        acc.push(amenity);
      }
      return acc;
    }, []);

    if (tranformedAmenitiesWithSwimSpa.length) {
      const propertyAmenities = property.amenities.map(amenity => amenity.key);
      const foundAmenities = tranformedAmenitiesWithSwimSpa.reduce(
        (acc, amenity) => {
          if (propertyAmenities.includes(amenity)) {
            acc += 1;
          }
          return acc;
        },
        0
      );
      const ret = foundAmenities === tranformedAmenitiesWithSwimSpa.length;
      return ret;
    }
    return true;
  };

  const bedrooms = urlParams.get('bedrooms') || 0,
    bathrooms = urlParams.get('baths') || 0,
    sleeps = urlParams.get('sleeps') || 0,
    amenities = urlParams.get('amenities')
      ? urlParams.get('amenities').split(',')
      : [];

  const propertyFilterDisplayHeader = getPropertyDisplayHeader(
    bedrooms,
    bathrooms,
    sleeps
  );

  const properties = propertyBuildData.filter(property => {
    const ret1 =
      property.bedrooms >= Number(bedrooms) &&
      property.bathrooms >= Number(bathrooms) &&
      property.sleeps >= Number(sleeps) &&
      hasAllAmenities(property, amenities);
    return ret1;
  });

  console.log(
    'properties',
    properties.filter(property => property.name == 'Aquarius South')
  );
  if (!properties) {
    return <div>Loading....</div>;
  }

  const getUrlQueryString = () => {
    return (
      urlParams.entries &&
      Array.from(urlParams.entries())
        .reduce((acc, [key, value]) => {
          if (value && key !== 'mode') {
            acc.push(`${key}=${value}`);
          }
          return acc;
        }, [])
        .join('&')
    );

    return '';
  };
  const urlQueryString = getUrlQueryString();
  const mapViewData = { bhip: { properties } };
  const displayMode = viewMode ? '' : 'none';
  const PageLabelDisplay = () => {
    switch (viewMode) {
      case 'map':
        return '(Map)';
      case 'grid':
        return '(Search)';
      default:
        return '';
    }
  };

  const getFilteredPropertiesByDateRange = (
    propertiesByDateRange,
    filteredProperties
  ) => {
    const propertyDateRangeFilterObject = propertiesByDateRange.reduce(
      (acc, property) => {
        acc[property.key] = property.id;
        return acc;
      },
      {}
    );
    return filteredProperties.filter(
      property => property.id === propertyDateRangeFilterObject[property.key]
    );
  };
  const sortPropertiesByPlatform = selectedProperty => {
    const propertyListings =
      selectedProperty &&
      selectedProperty.propertyListings.map(listing => {
        switch (listing.platform.key) {
          case 'vrbo': {
            return { ...listing, sortNumber: 1 };
          }
          case 'homeaway': {
            return { ...listing, sortNumber: 2 };
          }
          case 'bookingcom': {
            return { ...listing, sortNumber: 3 };
          }
          case 'airbnb': {
            return { ...listing, sortNumber: 4 };
          }
          case 'tripadvisor': {
            return { ...listing, sortNumber: 5 };
          }
          default:
            return listing;
        }
      });

    const sortedListings = propertyListings
      ? propertyListings.sort((a, b) => a.sortNumber - b.sortNumber)
      : [];

    return {
      ...selectedProperty,
      propertyListings: sortedListings,
    };
  };
  const getSelectedProperty = (id, properties) => {
    const selectedProperty = id
      ? properties.find(property => property.key === id)
      : null;
    const hasVRBO =
      selectedProperty &&
      selectedProperty.propertyListings.find(
        listing => listing.platform.key === 'vrbo'
      );
    if (selectedProperty && !hasVRBO) {
      const found = selectedProperty.propertyListings.find(
        listing => listing.platform.key === 'homeaway'
      );
      if (found) {
        selectedProperty.propertyListings.push({
          propertyUrl: found.propertyUrl,
          platform: { key: 'vrbo' },
        });
      }
    }

    return sortPropertiesByPlatform(selectedProperty);
  };

  function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  return (<>
    {location.state?.paypalSuccess && <Modal
      // expanded={true}
      disableFocusTrap={true}
      isOpen={paypalMessagingModalIsOpen}
      title={'Property Reservation'}
      customHeader={() => {
        return (
          <>
            <h5 className="modal-title" id="paypal-success-do-not-remove">
              <CheckCircle color="green" className="mr-1" />
              Thank you for your payment!
            </h5>
          </>
        );
      }}
      onClose={() => setPaypalMessagingModalIsOpen(false)}
    >
      <>
        <p className="mb-2">
          Your dates are now reserved. We will email you shortly to confirm
          your details and finalize your reservation.
        </p>
        <div className="mb-1plus">
          {location.state?.paypalSuccess?.propertyQuote?.property?.name}
        </div>
        <div className="mb-1plus">
          <span>
            {moment(location.state?.paypalSuccess?.checkIn)
              .format('dddd')
              .substring(0, 3) +
              ', ' +
              moment(location.state?.paypalSuccess?.checkIn).format('MMM DD, YYYY')}{' '}
          </span>{' '}
          -{' '}
          <span>
            {moment(location.state?.paypalSuccess?.checkOut)
              .format('dddd')
              .substring(0, 3) +
              ', ' +
              moment(location.state?.paypalSuccess?.checkOut).format('MMM DD, YYYY')}{' '}
          </span>
        </div>
        <button
          className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
          onClick={() => {
            setPaypalMessagingModalIsOpen(false);
          }}
        >
          OK
        </button>
      </>
    </Modal>}
    <PropertySelectProvider>
      <PropertySelectConsumer>
        {context => {
          const { bedroomsState, bathroomsState, sleepState } = context?.state;
          const { setPropertyTypeValue } = context;

          return (
            <Query
              query={AVAILABLE_PROPERTIES_QUERY}
              skip={!urlParams.get('checkIn')}
              variables={{
                checkIn: urlParams.get('checkIn'),
                checkOut: urlParams.get('checkOut'),
                // checkIn: urlParams.get('preserveCheckIn')
                //   ? urlParams.get('checkIn')
                //   : null,
                // checkOut: urlParams.get('preserveCheckIn')
                //   ? urlParams.get('checkOut')
                //   : null,
                flexibilityInDays: Number(urlParams.get('flexibilityInDays')),
              }}
            >
              {({ data, loading, error }) => {
                const filteredProperties =
                  data && !objectIsEmpty(data)
                    ? getFilteredPropertiesByDateRange(
                      data.properties,
                      properties
                    )
                    : properties;
                console.log('filteredProperties', filteredProperties);
                const propertiesForDisplay =
                  filteredProperties.length > 0
                    ? filteredProperties
                    : propertyBuildData;
                console.log('propertiesForDisplay', propertiesForDisplay);
                const selectedProperty = getSelectedProperty(
                  reserveDatesPropertyId,
                  properties
                );
                const noPropertyMatches =
                  filteredProperties?.length === 0 ? true : false;
                // state below has been added because React needs to rerender after graphql query returns results
                // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
                setHasQueryResults(true);
                return (
                  <Layout
                    location={location}
                    searchbarLabel={`${sitePage.title} ${PageLabelDisplay()}`}
                    showFooter={viewMode === 'grid'}
                    stickyRow={() => {
                      return (
                        <div
                          className={`${viewMode === 'grid' ? 'mb-2' : 'mb-0 mb-sm-2'
                            } sticky-row-md-down container bg-white pt-2 pb-lg-1plus pb-lg-2`}
                        >
                          <div
                            ref={searchBarRowRef}
                            id="search-bar-row"
                            className={`${styles.searchbarRow} d-flex justify-content-between mb-1 mb-lg-1plus`}
                          >
                            <div className="d-lg-flex align-items-center">
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className={`${styles.amenityToggleButton} mt-0plus btn btn-outline-primary rounded font-weight-bold d-lg-none d-block w-lg-auto`}
                                  data-toggle="modal"
                                  onClick={() => setAmenitiesModalIsOpen(true)}
                                // data-target="#amenity-modal"
                                // id="amenity-filter-menu-toggle"
                                >
                                  <>
                                    Filter{' '}
                                    <span className={`d-none d-md-inline`}>
                                      By Amenities
                                    </span>
                                  </>
                                </button>
                                <button
                                  type="button"
                                  className={`${styles.amenityToggleButton}  btn btn-outline-primary dropdown-toggle rounded font-weight-bold d-none d-lg-block`}
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  id="amenity-filter-menu-toggle"
                                >
                                  Filter{' '}
                                  <span className="d-none d-md-inline">
                                    By Amenities
                                  </span>
                                </button>
                                <div
                                  id="amenities-dropdown-menu"
                                  className={`dropdown-menu keep-open rounded ${styles.amenitiesDropdownMenu}`}
                                >
                                  <AmenitiesForm
                                    propertyAmenities={possibleAmenities}
                                    type="dropdown"
                                    bedrooms={bedrooms}
                                    bathrooms={bathrooms}
                                    sleeps={sleeps}
                                    amenities={amenities}
                                    location={location}
                                    setAmenitiesModalIsOpen={
                                      setAmenitiesModalIsOpen
                                    }
                                  />
                                </div>
                                <button
                                  id="check-availability-button"
                                  data-toggle="modal"
                                  onClick={() =>
                                    setAvailabilityModalIsOpen(true)
                                  }
                                  className="btn btn-secondary font-weight-bold text-primary mx-2"
                                >
                                  <span className={`d-none d-md-inline`}>
                                    Search Properties By Travel Dates
                                  </span>
                                  <span className={`d-md-none`}>
                                    Availability
                                  </span>

                                </button>
                              </div>
                            </div>
                            <div
                              className={`${styles.iconSearchNavigation} d-flex align-items-end`}
                            >
                              <span
                                className="d-inline d-lg-none mr-1plus"
                                // data-toggle="modal"
                                // data-target="#search-rentals"
                                onClick={() => setSearchModalIsOpen(true)}
                              >
                                <img
                                  alt="Search Properties"
                                  src="/search.svg"
                                  width="21.8"
                                  height="21.8"
                                />
                              </span>
                              {viewMode && viewMode === 'grid' && (
                                <div
                                  id="map-view-button"
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    setViewMode('map');
                                    navigate(
                                      `/rentals?${urlQueryString
                                        ? `${urlQueryString}&mode=map`
                                        : `mode=map`
                                      }`
                                    );
                                  }}
                                >
                                  {' '}
                                  <img
                                    alt="Map View"
                                    src="/map.svg"
                                    width="17"
                                    height="20"
                                  />
                                  <span className="text-primary ml-1plus d-none d-lg-inline">
                                    Map View
                                  </span>
                                  <span
                                    className={`${styles.modeToggleText} d-inline d-lg-none text-primary ml-1plus`}
                                  >
                                    Map
                                  </span>
                                </div>
                              )}
                              {viewMode === 'map' && (
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    setViewMode('grid');
                                    navigate(
                                      `/rentals?${urlQueryString
                                        ? `${urlQueryString}&mode=grid`
                                        : `mode=grid`
                                      }`
                                    );
                                  }}
                                >
                                  <img
                                    alt="Grid View"
                                    src="/grid.svg"
                                    width="19"
                                    height="20"
                                  />
                                  <span className="text-primary ml-1plus d-none d-lg-inline">
                                    Grid View
                                  </span>
                                  <span
                                    className={`${styles.modeToggleText} d-inline d-lg-none text-primary ml-1plus`}
                                  >
                                    Grid
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <ChipsList
                            filteredProperties={filteredProperties}
                            loading={loading}
                            location={location}
                            amenities={possibleAmenities}
                            setPropertyTypeValue={(key, value) =>
                              setPropertyTypeValue(key, value)
                            }
                          />
                        </div>
                      );
                    }}
                  >
                    <SEO
                      title={sitePage.seoTitle}
                      description={sitePage.seoDescription}
                      headTags={sitePage.headTags}
                    >
                      <link
                        rel="canonical"
                        href={`https://www.beachhousesinparadise.com/rentals`}
                      />
                    </SEO>

                    <div
                      id="map-view-page-container"
                      className={`${viewMode === 'map'
                        ? `${styles.mapViewPageContainer} px-0`
                        : ''
                        } container pt-0`}
                    >
                      {!loading && noPropertyMatches && hasQueryResults ? (
                        <div
                          ref={alertWarning}
                          id="alert-warning"
                          className="alert alert-warning d-flex px-1plus"
                          role="alert"
                        >
                          <span className="mt-1plus">
                            <img
                              className="no-resize"
                              alt="Alert"
                              src="/alert-info.svg"
                              width="30"
                              height="30"
                            />
                          </span>
                          <span className="ml-1plus font-color-black">
                            We don't have an exact match for your search. We're
                            showing results <b>without any filters</b> applied.
                            You can modify your filters to see homes that better
                            match your search
                          </span>
                        </div>
                      ) : null}
                      {viewMode && viewMode === 'map' && (
                        <VacationRentalMap
                          data={propertiesForDisplay}
                          noPropertyMatches={noPropertyMatches}
                        />
                      )}

                      {viewMode === 'grid' && (
                        <div id="grid-wrapper" className="row px-2">
                          {loading && <Loading />}
                          {!loading &&
                            propertiesForDisplay &&
                            propertiesForDisplay.sort((a, b) => {
                              const nameA = `${a.masterPlatformListing.payoutType}`;
                              const nameB = `${b.masterPlatformListing.payoutType}`;
                              console.log('a.masterPlatformListing.payoutType', a.masterPlatformListing.payoutType)
                              if (nameA < nameB) {
                                return 1;
                              }
                              if (nameA > nameB) {
                                return -1;
                              }

                              // names must be equal
                              return 0;

                            })
                              .filter(
                                property =>
                                  property.sitePage.featuredImageCollection
                              )
                              .map((filteredProperty, index) => {
                                return (
                                  <div
                                    className="col-sm-12 col-lg-6 col-xl-4 p-0plus mb-3"
                                    key={filteredProperty.id}
                                  >
                                    <div className="no-gutters-sm-down">
                                      <Link
                                        to={`/${filteredProperty.sitePage.slug}`}
                                        className="aspect ratio-4x3 mb-2"
                                      >
                                        <RandomImageGroup
                                          files={
                                            filteredProperty.sitePage
                                              .featuredImageCollection.files
                                          }
                                        />

                                        {filteredProperty.status ===
                                          'COMING_SOON' && (
                                            <span className="thumbnail-label">
                                              Coming Soon
                                            </span>
                                          )}
                                      </Link>
                                    </div>

                                    <div className="d-flex align-items-center">
                                      <Link
                                        to={`/${filteredProperty.sitePage.slug}`}
                                        className="h2 font-weight-bold mb-0"
                                      >
                                        {filteredProperty.name}
                                      </Link>
                                      {filtersApplied &&
                                        filteredProperties.length > 0 && (
                                          <button
                                            onClick={() =>
                                              allowBooking ? sendToReservePage(filteredProperty.sitePage.slug) : setReserveDatesPropertyId(
                                                filteredProperty.key
                                              )
                                            }
                                            className="btn btn-outline-primary ml-1plus font-weight-bold d-flex align-items-center py-1 px-1pus"
                                          >
                                            <span>
                                              <span>Reserve</span>
                                              <span
                                                id={styles.yourText}
                                                className={`ml-1`}
                                              >
                                                your
                                              </span>
                                              <span
                                                id={styles.datesText}
                                                className={`ml-1`}
                                              >
                                                Dates
                                              </span>
                                            </span>
                                          </button>
                                        )}
                                    </div>

                                    <div className="mt-1plus">
                                      <PropertyDetailsList
                                        property={filteredProperty}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      )}
                    </div>
                    <Modal
                      isOpen={moreOptionsModalIsOpen}
                      title={'More Options'}
                      onClose={() => setMoreOptionsModalIsOpen(false)}
                      disableFocusTrap={true}
                    >
                      {selectedProperty &&
                        selectedProperty.additionalMessaging && (
                          <p>{selectedProperty.additionalMessaging}</p>
                        )}
                    </Modal>
                    <Modal
                      isOpen={reserveDatesPropertyId ? true : false}
                      title={'How to Book'}
                      onClose={() => setReserveDatesPropertyId(false)}
                      disableFocusTrap={true}
                    >
                      {selectedProperty && (
                        <>{selectedProperty.additionalMessaging}</>
                      )}
                    </Modal>
                    <Modal
                      isOpen={amenitiesModalIsOpen}
                      title={'Filter By Amenities'}
                      onClose={() => setAmenitiesModalIsOpen(false)}
                    >
                      <AmenitiesForm
                        propertyAmenities={possibleAmenities}
                        bedrooms={bedrooms}
                        bathrooms={bathrooms}
                        sleeps={sleeps}
                        amenities={amenities}
                        type="modal"
                        location={location}
                        setAmenitiesModalIsOpen={setAmenitiesModalIsOpen}
                      />
                    </Modal>
                    <Modal
                      isOpen={availabilityModalIsOpen}
                      disableFocusTrap={true}
                      title={'Availability'}
                      onClose={() => setAvailabilityModalIsOpen(false)}
                    >
                      <CheckAvailabilityForm
                        loading={loading}
                        results={filteredProperties.length}
                        onClose={() => setAvailabilityModalIsOpen(false)}
                      />
                    </Modal>
                    <Modal
                      isOpen={searchModalIsOpen}
                      title={'Beach House Rentals'}
                      onClose={() => setSearchModalIsOpen(false)}
                    >
                      <PropertySearchForm
                        modalIsOpen={searchModalIsOpen}
                        id="header-search-form"
                        onClose={() => setSearchModalIsOpen(false)}
                      />
                    </Modal>
                  </Layout>
                );
              }}
            </Query>
          );
        }}
      </PropertySelectConsumer>
    </PropertySelectProvider>
  </>);
};
// const PropertyTypeSelectConsumer = PropertySelectContext.Consumer;
// export { PropertyTypeSelectConsumer };

export const query = graphql`
  query Rentals($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties(where: { type: STANDARD }) {
        status
        additionalMessaging
        priceOffSeason
        id
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        addressDisplay
        streetAddress1
        latitude
        longitude
        masterPlatformListing {
          payoutType
        }
        # ... on PropertyCombo {
        #   masterPlatformListing {
        #     payoutType
        #   }
        #   calendar {
        #     start
        #     availability(ignoreMinPriorNotify: true)
        #   }
        #   childProperties {
        #     id
        #     key
        #     label
        #   }
        # }
        amenities {
          key
          name
        }
        sitePage {
          slug
          status
          excerpt
          featuredImageCollection {
            files {
              tiny: version(where: { key: "tiny" }) {
                fullUrl
              }
              medium: version(where: { key: "medium" }) {
                fullUrl
              }
            }
          }
          # featuredImage {
          #   file {
          #     tiny: version(where: { key: "tiny" }) {
          #       fullUrl
          #     }
          #     medium: version(where: { key: "medium" }) {
          #       fullUrl
          #     }
          #   }
          # }
        }
        propertyListings {
          propertyUrl
          platform {
            name
            key
          }
        }
      }
      propertyAmenities {
        key
        name
      }
    }
  }
`;

export default withApollo(SearchPage);
